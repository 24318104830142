:root {
  --active-component-keyColor: #72ffbd;
  --hover-component-keyColor: #1d91d4;
}

// avoid unintended scroll
.App {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

code {
  font-family: ui-monospace, ".SF Mono", Menlo, Consolas;
}

.tabular-nums {
  font-variant: tabular-nums;
}

input:disabled,
button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

input[type="text"] {
  font: inherit;
  border: #000 2px solid;
  background: #111;
  color: #eee;
  padding: 0.3em;
  border-radius: 0.2em;
  margin-right: 0.3em;
  line-height: 1.2em;

  &:hover {
    border-color: var(--hover-component-keyColor);
  }
}

select {
  appearance: none;
  cursor: pointer;
  @extend input, [type=text];
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
  padding-right: 1.7em;
}

button,
.button-like {
  font: inherit;
  border: #272727 2px solid;
  background: rgb(56, 56, 56);
  color: #eee;
  cursor: pointer;
  padding: 0.3em 0.45em;
  border-radius: 0.2em;
  margin-right: 0.3em;
  line-height: 1.2em;

  &:disabled {
    border-color: rgb(56, 56, 56);
  }

  &:hover {
    border-color: var(--hover-component-keyColor);
  }
  &:focus {
    border-color: var(--active-component-keyColor);
  }
}

input[type="radio"] {
  cursor: pointer;
  appearance: none;
  border: 4px solid rgb(112, 112, 112);
  background: black;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0;
  transition: 0.05s ease border;

  &:hover {
    border-color: var(--hover-component-keyColor);
  }

  &.is-selected {
    appearance: none;
    border: 7.5px solid var(--active-component-keyColor);
  }

  & + label {
    cursor: pointer;
    margin: 0 0.4em 0 0.33em;
    vertical-align: top;
    line-height: 20px;

    &:hover {
      text-decoration: underline;
    }
  }
}

input[type="range"] {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

details summary {
  cursor: pointer;
  padding: 0.3em 0.2em;
  border-radius: 2px;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
}

.tap-clap-button {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>👐</text></svg>")
      16 0,
    auto;

  &:active {
    cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>👏</text></svg>")
        16 0,
      auto;
  }
}

.no-controllers {
  display: none;
}

.controllers {
  padding: 16px;
  user-select: none;
  display: inline-block;
  opacity: 0.5;

  max-width: 600px;
  max-height: 90vh;
  @media (max-width: 480px) {
    max-height: 80vh;
  }

  overflow: scroll;
  transition: all 0.15s ease 0s;
  text-shadow: 0 0 0.7em rgba(0, 0, 0, 0.5);

  &:hover {
    opacity: 1;
    backdrop-filter: blur(8px);
    background: rgba(0, 0, 0, 0.4);
    text-shadow: none;
  }
}

.controller-section {
  .controller-section__title {
    margin: 1em 0 0.6em;
    font-size: 1em;
    display: flex;
    align-items: center;

    &:after {
      content: "";
      flex: 1;
      margin-left: 0.5em;
      margin-top: 0.07em;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.6);
    }
  }

  &:first-child .controller-section__title {
    margin-top: 0;
  }

  & & {
    margin-left: 0.2em;
    padding-left: 0.8em;
    position: relative;
    border-left: 2px solid rgba(255, 255, 255, 0.4);

    .controller-section__title {
      font-size: 0.9em;
      margin: 0.9em 0 0.7em;

      &:after {
        background: transparent;
      }
    }
  }
}

.images-list {
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    display: inline-block;
    height: 40px;
    width: 71px;
    border-bottom: 4px solid transparent;
    margin-bottom: 8px;

    &.selected {
      border-bottom: 4px solid var(--active-component-keyColor);
      animation: indicatorBlink 0.5s ease-out 0.4s infinite;

      @keyframes indicatorBlink {
        0% {
          border-bottom: 4px solid var(--active-component-keyColor);
        }
        100% {
          border-bottom: 4px solid transparent;
        }
      }
    }

    &:nth-child(4n) {
      margin-right: 8px;
    }
  }
}

.add-image-form {
}
.add-image-form input {
}

.switching-strategy-selector {
  margin: 0.4em 0 0;

  .switching-strategy-selector__strategy {
    margin-right: 0.6em;
  }
}

.switcher {
  margin-top: 0.4em;
}

.switcher__interval {
  .switcher__interval__value-label {
    margin: 0 0.3em;
  }
}

.tenor-adder {
  margin: 0 0 0.4em;

  .tenor-search-results {
    margin-top: 0.4em;
  }

  details {
    margin: 0.3em 0.1em;

    span {
      margin-right: 0.6em;
    }
  }
}

a {
  color: var(--active-component-keyColor);
  padding: 0.3em;
  border-radius: 0.2em;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    background: rgba(255, 255, 255, 0.2);
  }
}

.youtube-overlay {
  .youtube-overlay__current-state {
    .youtube-overlay__getset {
      margin-top: 0.4em;
      line-height: 1.2em;
    }

    .youtube-overlay__thumbnail {
      margin-top: 0.4em;
      img {
        width: 80px;
        height: 45px;
        object-fit: cover;
      }
    }
  }
}

.arbitary-iframe-overlay {
  .arbitary-iframe-overlay__getset {
    margin-top: 0.4em;
  }
}

.overlay-mixer {
  margin: 0.4em 0;
}

.thumbnail-with-action {
  position: relative;
  display: inline-block;

  img {
    height: 40px;
    width: 71px;
    object-fit: cover;
  }
}

.thumbnail-with-action__button {
  position: absolute;
  box-sizing: border-box;
  right: 0;
  bottom: 2px;
  min-width: 20px;
  height: 20px;
  padding: 0 0.2em;
  margin: 0.2em;
  opacity: 0.6;
  vertical-align: middle;

  &:hover {
    opacity: 1;
  }
}

@mixin arenaScreen {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  background: black;

  user-select: none;
  pointer-events: none;
}

.arena__content {
  @include arenaScreen();
  z-index: -2;
}

.arena__content__overlay-wrapper {
  z-index: -1;
}
.arena__overlay__youtubeEmbed {
  @include arenaScreen();
  z-index: inherit;
}
.arena__overlay__arbitary-iframe {
  @include arenaScreen();
  z-index: inherit;
  border: none;
}

.sync-indicator {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 0px;
  height: 0px;
  border: 6px solid rgb(92, 199, 49);
  border-radius: 50%;
  mix-blend-mode: hard-light;

  &.sync-indicator__fresh {
    border-color: yellow;
  }

  &.sync-indicator__disconnected {
    border-color: rgb(255, 50, 35);
    animation: disconnectedIndicator 0.6s ease 0s infinite;
    @keyframes disconnectedIndicator {
      0% {
        border-color: rgb(255, 50, 35);
        opacity: 1 !important;
      }
      50% {
        border-color: yellow;
        opacity: 0.6 !important;
      }
      100% {
        border-color: rgb(255, 50, 35);
        opacity: 1 !important;
      }
    }
  }
}

.ping-value {
  position: absolute;
  bottom: 8px;
  right: 8px;
  opacity: 0.4;
  font-size: 0.8em;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  mix-blend-mode: normal;
  text-align: right;
  pointer-events: none;
  user-select: none;
}

.splash-screen {
  text-align: center;
  margin-top: 40vh;
  transform-origin: center;
  pointer-events: none;
  user-select: none;

  .splash-screen__title {
    letter-spacing: 0.014em;
    font-size: 3em;
    opacity: 0.4;
    font-weight: 900;
    animation: splashScreenLaunch 2s ease 0s 1;

    @keyframes splashScreenLaunch {
      0% {
        opacity: 0;
        transform: scale(0.83);
      }
      100% {
        opacity: 0.4;
        transform: scale(1);
      }
    }
  }

  .splash-screen__error {
    color: yellow;
    opacity: 0.8;
    font-size: 1rem;
    font-weight: normal;
    animation: splashScreenError 0.85s ease-out 0s infinite;
    margin-top: 0.2em;
    line-height: 1.34em;

    code {
      user-select: text;
      pointer-events: all;
    }

    @keyframes splashScreenError {
      0% {
        color: red;
      }
      100% {
      }
    }
  }
}
