@import "./App.scss";

.page__home {
  text-align: center;
  font-size: 2em;
  line-height: 1.3;
  padding: 3em 2em 0;

  @media (max-width: 480px) {
    font-size: 1.1em;
    padding: 3em 0.3em;
    input {
      display: block;
      margin: 0.2em auto;
    }
  }

  .logo {
    width: 300px;
    max-width: 60vw;
    animation: beat 0.7s ease-out infinite 1.4s;

    @keyframes beat {
      0% {
        transform: scale(1);
      }
      12% {
        transform: scale(1.01);
      }
      26% {
        transform: scale(0.95);
      }
      100% {
        transform: scale(1);
      }
    }
  }

  h1 {
    font-weight: 900;
    margin-bottom: 0;
  }

  input {
    margin-bottom: 0em;
  }

  .start-session {
    text-decoration: none;
    display: inline-block;
    margin: 1em auto 0;

    &.invalid {
      pointer-events: none;
      opacity: 0.8;
    }
  }

  p {
    font-size: 0.7em;
    opacity: 0.8;
    padding: 0 1em;

    a {
      color: cyan;
    }
  }
}
